import { InitAppForPageFn } from 'yoshi-flow-editor-runtime';
import * as _ from 'lodash';

let pServices: any;
const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServices,
  flowAPI,
) => {
  pServices = platformServices;
};

const getPlatformServices = (): any => pServices;

export { getPlatformServices, initAppForPage };
