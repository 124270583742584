import React from 'react';
import { ModalWrapper } from './ModalWrapper';
import { Button, SIZE, PRIORITY } from 'wix-ui-tpa/Button';
import styles from './Modal.st.css';

interface ConfirmationModalProps {
  mobile: boolean;
  closeConfirmation: Function;
  preview: boolean;
  style: any;
}

export const ConfirmationModal = ({
  closeConfirmation,
  mobile,
  preview,
  style,
}: ConfirmationModalProps) => (
  <ModalWrapper
    preview={preview}
    close={closeConfirmation}
    mobile={mobile}
    style={style}
    title="Disabled Sale of Information"
    text="You have disabled the sale of personal information to third parties."
  >
    <Button
      data-hook="ccpa-confirm-close"
      size={mobile ? SIZE.small : SIZE.medium}
      onClick={() => closeConfirmation()}
      className={styles.button}
      priority={PRIORITY.primary}
      tabIndex={0}
    >
      Close
    </Button>
  </ModalWrapper>
);
