import React from 'react';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { Text } from 'wix-ui-tpa/Text';
import { TextButton } from 'wix-ui-tpa/TextButton';
import styles from './Widget.st.css';
import { ConfirmationModal } from './modal/ConfirmationModal';
import { SetCCPAModal } from './modal/SetCCPAModal';
import { ErrorModal } from './modal/ErrorModal';
import { Loader } from './modal/Loader';
import { ModalState } from '../controller';

interface WidgetProps {
  mobile: boolean;
  openModal: Function;
  optOut: Function;
  close: Function;
  closeConfirmation: Function;
  shouldShowLink: boolean;
  modalState: ModalState;
  host: any;
  logModalIsOpen: any;
}

export default class extends React.Component<WidgetProps> {
  render() {
    const { mobile, ...rest } = this.props;

    return (
      <TPAComponentsProvider value={{ mobile }}>
        <Widget mobile={mobile} {...rest} />
      </TPAComponentsProvider>
    );
  }
}

export const Widget = ({
  modalState,
  openModal,
  shouldShowLink,
  host,
  ...rest
}: WidgetProps) => (
  <span className={styles.ccpa}>
    <div {...styles('root', {}, rest)} data-hook="DoNotSellMyData-wrapper">
      {shouldShowLink && (
        <TextButton
          data-hook="ccpa-link"
          aria-haspopup="dialog"
          className={styles.link}
          onClick={() => {
            openModal();
          }}
        >
          Do Not Sell My Personal Information
        </TextButton>
      )}
      {!shouldShowLink && (
        <Text className={styles.text} data-hook="ccpa-text">
          Your data will not be transferred to any 3rd party
        </Text>
      )}
      {modalState === ModalState.SET_CCPA && (
        <SetCCPAModal
          {...rest} style={host.style} preview={host.viewMode === 'Preview'} />
      )}
      {modalState === ModalState.CONFIRMATION && (
        <ConfirmationModal
          {...rest} style={host.style} preview={host.viewMode === 'Preview'} />
      )}
      {modalState === ModalState.LOADER && <Loader />}
      {modalState === ModalState.ERROR && (
        <ErrorModal
          {...rest} style={host.style} preview={host.viewMode === 'Preview'} />
      )}
    </div>
  </span>
);
