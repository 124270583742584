import React from 'react';
import { ModalWrapper } from './ModalWrapper';
import { Button, PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import styles from './Modal.st.css';

interface SetCCPAModalProps {
  mobile: boolean;
  preview: boolean;
  optOut: Function;
  close: Function;
  logModalIsOpen: Function;
  style: any;
}

export const SetCCPAModal = ({
  close,
  optOut,
  mobile,
  logModalIsOpen,
  preview,
  style,
}: SetCCPAModalProps) => {
  logModalIsOpen();
  return (
    <ModalWrapper
      mobile={mobile}
      preview={preview}
      style={style}
      close={() => close('close')}
      title="Do Not Sell My Personal Information"
      text="To disable the sale of your information to third parties, click Submit."
    >
      <Button
        size={mobile ? SIZE.small : SIZE.medium}
        priority={PRIORITY.secondary}
        onClick={() => {
          close('cancel');
        }}
        data-hook="ccpa-cancel"
        className={styles.button}
        tabIndex={0}
      >
        Cancel
      </Button>
      <Button
        data-hook="ccpa-optout"
        size={mobile ? SIZE.small : SIZE.medium}
        priority={PRIORITY.primary}
        onClick={() => {
          optOut();
        }}
        className={styles.button}
        tabIndex={0}
      >
        Submit
      </Button>
    </ModalWrapper>
  );
};
