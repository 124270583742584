import {
  CreateControllerFn,
  ControllerParams,
} from 'yoshi-flow-editor-runtime';
import { getSiteTranslations } from '../../config/i18n';
import { getPlatformServices } from '../../viewer.app';
import initSchemaLogger from '@wix/bi-logger-ccpa-consent';

export enum ModalState {
  NONE,
  SET_CCPA,
  CONFIRMATION,
  ERROR,
  LOADER,
}

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const biLogger = initSchemaLogger(getPlatformServices().biLoggerFactory())();

  const { appParams, setProps, wixCodeApi } = controllerConfig;
  const { fedopsLogger, getSiteLanguage, widgetId, isMobile } = flowAPI;

  const genCcpaPolicy = (policy: any): any => ({
    ...policy.policy,
    dataToThirdParty: false,
    advertising: false,
  });

  const shouldShowLink = (policy: any): boolean =>
    policy && (policy.defaultPolicy || policy.policy.dataToThirdParty);

  const setModalState = (newState: ModalState) => {
    state.modalState = newState;
    setProps({
      modalState: state.modalState,
    });
  };

  const initInViewer = () => {
    const policy = (wixCodeApi.user as any).getCurrentConsentPolicy();
    const state = {
      shouldShowLink: shouldShowLink(policy),
      modalState: ModalState.NONE,
    };

    const setCCPAPolicy = async () => {
      fedopsLogger.interactionStarted('optOut');
      try {
        setModalState(ModalState.LOADER);
        const curPolicy = (wixCodeApi.user as any).getCurrentConsentPolicy();
        const ccpaPolicy = genCcpaPolicy(curPolicy);
        await (wixCodeApi.user as any).setConsentPolicy(ccpaPolicy);

        setModalState(ModalState.CONFIRMATION);
      } catch (error) {
        setModalState(ModalState.ERROR);
        throw error;
      } finally {
        fedopsLogger.interactionEnded('optOut');
      }
    };

    return { state, setCCPAPolicy };
  };

  const initInEditor = () => {
    return {
      state: {
        shouldShowLink: true,
        modalState: ModalState.NONE,
      },
      setCCPAPolicy: () => {
        state.shouldShowLink = false;
        setProps({
          shouldShowLink: state.shouldShowLink,
        });
      },
    };
  };

  const appData =
    typeof (wixCodeApi.user as any).getCurrentConsentPolicy === 'function'
      ? initInViewer()
      : initInEditor();

  const { state, setCCPAPolicy } = appData;

  registerToPolicyChangeEvent();

  const language = getSiteLanguage();
  const translations = await getSiteTranslations(language);
  const { baseUrls = {}, appDefinitionId } = appParams;

  async function registerToPolicyChangeEvent() {
    const policyChangeFunc = (wixCodeApi.user as any).onConsentPolicyChanged;
    if (typeof policyChangeFunc === 'function') {
      policyChangeFunc((policy: any) => {
        state.shouldShowLink = shouldShowLink(policy);
        setProps({
          shouldShowLink: state.shouldShowLink,
        });
      });
    }
  }

  return {
    async pageReady() {
      setProps({
        cssBaseUrl: baseUrls.staticsBaseUrl,
        language,
        mobile: isMobile(),
        translations,
        modalState: state.modalState,
        shouldShowLink: state.shouldShowLink,
        openModal: async () => {
          biLogger.cookiesBannerUouUouClickOnDoNotSellMyDataLink({});
          setModalState(ModalState.SET_CCPA);
        },
        logModalIsOpen: async () => {
          biLogger.cookiesBannerUouUouDisableDataTransfersPopUpIsDisplay({});
        },
        optOut: async () => {
          biLogger.cookiesBannerUouUouWithDisableDataTransfersPopUp({
            action: 'disable transfers',
          });
          setCCPAPolicy();
        },
        closeConfirmation: () => {
          wixCodeApi.location.to &&
            wixCodeApi.location.to(wixCodeApi.location.url);
          setModalState(ModalState.NONE);
        },
        close: async (action: string) => {
          if (action) {
            biLogger.cookiesBannerUouUouWithDisableDataTransfersPopUp({
              action,
            });
          }
          setModalState(ModalState.NONE);
        },
      });
    },
  };
};

export default createController;
