import React from 'react';
import { ModalWrapper } from './ModalWrapper';
import { Button, PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import styles from './Modal.st.css';

interface ErrorModalProps {
  mobile: boolean;
  close: Function;
  preview: boolean;
  optOut: Function;
  style: any;
}

export const ErrorModal = ({
  close,
  mobile,
  optOut,
  preview,
  style,
}: ErrorModalProps) => (
  <ModalWrapper
    preview={preview}
    mobile={mobile}
    close={close}
    style={style}
    title="Something Went Wrong"
    text="Due to a temporary internal error, we couldn’t submit your request. Try again in a moment."
  >
    <Button
      size={mobile ? SIZE.small : SIZE.medium}
      priority={PRIORITY.secondary}
      onClick={() => close()}
      className={styles.button}
      tabIndex={0}
    >
      Close
    </Button>
    <Button
      size={mobile ? SIZE.small : SIZE.medium}
      priority={PRIORITY.primary}
      onClick={() => optOut()}
      className={styles.button}
      tabIndex={0}
    >
      Retry
    </Button>
  </ModalWrapper>
);
