import React from 'react';
import styles from './Modal.st.css';
import { LoaderImg } from '../../../../assets/images';

export const Loader = () => (
  <div className={styles.backdrop}>
    <div className={styles.loader}>
      <LoaderImg />
    </div>
  </div>
);
