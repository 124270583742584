import React from 'react';
import styles from './Modal.st.css';
import classNames from 'classnames';
import { Close } from '../../../../assets/images';

interface ModalWrapperProps {
  title: string;
  text: string;
  children: any;
  mobile: boolean;
  preview: boolean;
  close: Function;
  style: any;
}

const calcFont = (params: { defaultFont: any; overrideFont: any; max: number }) => {
  const { defaultFont, overrideFont, max } = params;
  let fontSize = parseInt(overrideFont ?
    overrideFont.size :
    defaultFont.size, 10);
  if (fontSize > max) {
    fontSize = max;
  }
  return fontSize;
}

export const ModalWrapper = ({
  title,
  text,
  children,
  mobile,
  close,
  preview,
  style
}: ModalWrapperProps) => {
  const fontTitle = calcFont({
    defaultFont: style.siteTextPresets['Heading-L'],
    overrideFont: style.styleParams.fonts.confirmationTitleFont,
    max: mobile ? 40 : 60
  });
  const fontBody = calcFont({
    defaultFont: style.siteTextPresets['Body-M'],
    overrideFont: style.styleParams.fonts.confirmationFont,
    max: mobile ? 28 : 50
  });

  return (
    <div className={styles.backdrop} data-hook="ccpa-modal" aria-modal={true}>
      <div
        className={classNames(styles.modal, {
          [styles.mobile]: mobile,
          [styles.preview]: preview,
        })}
      >
        <div className={styles.modalHeader}>
          <button onClick={() => close()} tabIndex={0}>
            <Close className={styles.close} />
          </button>
        </div>
        <h1 style={{ fontSize: `${fontTitle}px` }} className={styles.modalTitle}>{title}</h1>
        <h2 style={{ fontSize: `${fontBody}px` }} className={styles.modalText}>{text}</h2>
        <div className={styles.modalButtons}>{children}</div>
      </div>
    </div>
  );
}
